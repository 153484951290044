import React, { useState } from 'react'

//Packages
import moment from 'moment'
import InputMask from 'react-input-mask'

import { FaSpinner } from 'react-icons/fa'
import { MdLock, MdAddCircleOutline } from 'react-icons/md'

const Snippet = (props) => {
  const {
    nameInput,
    setNameInput,
    CardElement,
    CardNumberElement,
    CardExpiryElement,
    CardCvcElement,
    CARD_OPTIONS,
    provisionedUsersNumber,
    totalPurchaseAmount,
    emailInput,
    firstNameInput,
    lastNameInput,
    companyNameInput,
    updatePurchasePositionNumber,
    buttonAction,
    errorMessages,
    setErrorMessages,
    purchaseErrors,
    updatePurchaseErrors,
    cardErrorMessage,
    cardNameErrorMessage,
    cardExpirationErrorMessage,
    cardCVCErrorMessage,
    setCardErrorMessage,
    setCardNameErrorMessage,
    setCardExpirationErrorMessage,
    setCardCVCErrorMessage,
    purchasePlan,
    provideCCNowInput,
    setProvideCCNowInput,
    referralCodeInput,
    setReferralCodeInput,
    referralCodeRawInput,
    setReferralCodeRawInput,
    promoCode,
    setPromoCode,
    promoCodeStatus,
    setPromoCodeStatus,
    checkPromoCode,
    promoCodeLoading,
    priceVal,
  } = props

  return (
    <>
      <div class="row mt-1 d-flex align-items-center justify-content-center">
        <div
          // class="col-md-7 col-lg-5"
          class="col-lg-12"
        >
          <hr size={1} />

          <div class="h6">Item details</div>

          <div class="plan-selected d-flex flex-row align-items-center justify-content-start">
            <label class="w-25 font-weight-light small">Product</label>
            <div class="plan-selected-option">
              {purchasePlan === 'standard' ? 'Standard' : null}
              {/* {purchasePlan === 'plus' ? 'Plus' : null} */}
              {purchasePlan === 'pro' ? 'Pro' : null}
              {purchasePlan === 'guide-zion'
                ? 'Zion National Park - Guide'
                : null}
              {purchasePlan === 'guide-passadicos'
                ? 'Passadiços do Paiva - Guide'
                : null}
            </div>
          </div>
          {/* <div class="plan-users-selected d-flex flex-row align-items-center justify-content-start">
            <label class="w-25">Quantity</label>
            <div class="plan-users-selected-option">
              {provisionedUsersNumber}
            </div>
          </div> */}
          {/* <div class="mt-3 account-selected d-flex flex-row align-items-center justify-content-start">
            <label class="w-25">Admin</label>
            <div class="account-selected-option">
              {firstNameInput} {lastNameInput}
            </div>
          </div> */}
          {/* <div class="account-selected d-flex flex-row align-items-center justify-content-start">
            <label class="w-25">Company</label>
            <div class="account-selected-option">{companyNameInput}</div>
          </div> */}
          {/* <div class="account-selected d-flex flex-row align-items-center justify-content-start">
            <label class="w-25">Email</label>
            <div class="account-selected-option">{emailInput}</div>
          </div> */}
          {/* <div class="purchase-confirm-item mt-3 pt-1">
            <div class="h6">Plan</div>
            <div class="row black-4">
              <div class="col-5 py-1">Users</div>
              <div class="col-7 font-weight-light py-1">
                {provisionedUsersNumber}
              </div>
            </div>
            <div class="row black-4">
              <div class="col-5 py-1">Total</div>
              <div class="col-7 font-weight-light py-1">
                ${totalPurchaseAmount}/month
              </div>
            </div>
          </div> */}
          {/* <hr size={1} /> */}

          {/* <div class="h6">Payment details</div> */}

          <div class="plan-selected d-flex flex-row align-items-start justify-content-start">
            <label class="w-25 font-weight-light small">Total</label>

            <div class="plan-selected-option mt-1">
              {/* <div class="font-weight-light">
                Free 7 day trial - cancel anytime
              </div>
              <div class="font-weight-light">
                After trial period, billed monthly on{' '}
                {moment().add(7, 'days').format('MMM D')}
              </div> */}
              <div class="font-weight-light line-height-0">
                {/* {provisionedUsersNumber} guide x ${priceVal} */}
                {/* {provisionedUsersNumber > 1 ? 's' : ''}  */}
                {/* ={' '} */}
                <span class="font-weight-bold">
                  {/* ${(priceVal * provisionedUsersNumber).toFixed(2)}/mo. */}$
                  {(priceVal * provisionedUsersNumber).toFixed(2)}
                </span>
              </div>
            </div>

            {/* <div class="d-flex flex-column mt-3 p-3 rounded-corners border-1-gray-6">
              <div class="font-weight-normal">Add credit card</div>

              <div class="font-weight-light line-height-1">
                Adding your credit card now gives you uninterrupted access to
                Sidepath at the end of your free, 7-day trial.
              </div>
              <ul class="nav nav-pills small mt-2 ml-0">
                <li class="nav-item mr-1">
                  <button
                    class={`nav-link ${
                      provideCCNowInput === true ? 'active' : ''
                    }`}
                    onClick={() => {
                      setProvideCCNowInput(true)
                    }}
                  >
                    Add card{' '}
                    <MdAddCircleOutline
                      size={18}
                      style={{ marginTop: -2 }}
                      class="ml-1"
                    />
                  </button>
                </li>
                <li class="nav-item mr-1">
                  <button
                    class={`nav-link ${
                      provideCCNowInput === false ? 'active' : ''
                    }`}
                    onClick={() => {
                      setProvideCCNowInput(false)
                    }}
                  >
                    Not now
                  </button>
                </li>
              </ul>
            </div> */}
          </div>
          <hr size={1} />

          {/* {provideCCNowInput === true ? ( */}
          <>
            <div class="h6 d-flex flex-row align-items-center">
              <div>
                <div>Credit card details</div>
              </div>
              <div class="ml-auto d-flex flex-row align-items-end">
                <MdLock class="yellow-1" size={18} />
                <small class="ml-auto pl-1 yellow-1" style={{ paddingTop: 3 }}>
                  Secure
                </small>
              </div>
            </div>

            <div class="row mx-1">
              <div class="form-group w-100">
                <label for="userCardName" class="font-weight-light small">
                  Name on card
                </label>
                <input
                  type="text"
                  class="form-control"
                  id="userCardName"
                  aria-describedby="userCardNameHelp"
                  placeholder="Name"
                  value={nameInput}
                  onChange={(e) => setNameInput(e.currentTarget.value)}
                  tabIndex="4"
                />
                {cardNameErrorMessage ? (
                  <div class="p-1 small red-1 line-height-2">
                    {cardNameErrorMessage}
                  </div>
                ) : null}
              </div>
            </div>

            <div class="row mx-1">
              <div class="form-group d-flex flex-row w-100">
                <div class="flex-fill">
                  <label class="font-weight-light small">
                    Credit card number
                  </label>
                  <CardElement
                    options={CARD_OPTIONS}
                    className="form-control stripe-card-element-override"
                    onChange={(e) => {
                      console.log('CardNumberElement - e')
                      console.log(e)
                      if (e.error) {
                        setCardErrorMessage(e.error.message)
                      } else {
                        setCardErrorMessage('')
                      }
                    }}
                  />
                  {cardErrorMessage ? (
                    <div class="p-1 small red-1 line-height-2">
                      {cardErrorMessage}
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
            {/* <hr size={1} /> */}
          </>

          {/* <button
            class="btn btn-slim h6 w-100 text-left"
            type="button"
            data-toggle="collapse"
            data-target="#referralCodeContainer"
            aria-expanded="false"
          >
            <span class="caret d-inline-block"></span> Referral code
          </button> */}

          <div id="referralCodeContainer" class="collapse">
            <div class="plan-selected d-flex flex-column align-items-start justify-content-start">
              <div class="plan-selected-option mt-1">
                <div class="font-weight-light">
                  If you have a referral code, enter it below.
                </div>
              </div>
            </div>
            <div class="form-group">
              {/* <label for="userReferralCode">Referral code</label> */}
              <InputMask
                type="text"
                // mask="000-000-000"
                mask="***&#8202;&ndash;&#8202;***&#8202;&ndash;&#8202;***"
                //&#8202; is hairspace
                // maskPlaceholder="d"
                maskPlaceholder={null}
                class={`form-control form-control-sm ${
                  purchaseErrors && purchaseErrors.referral ? 'is-invalid' : ''
                }`}
                id="userReferralCode"
                placeholder="Referral code"
                value={referralCodeInput}
                onChange={(e) => {
                  setReferralCodeInput(e.target.value.toUpperCase())

                  const rawReferral = e.target.value
                    .replace(/\u2013|-|_|\s/g, '')
                    .toUpperCase()
                  setReferralCodeRawInput(rawReferral) //remove ndash, underscore from mask
                }}
                // tabIndex="3"
              />
            </div>
          </div>
          {/* <hr size={1} />

          <button
            class="btn btn-slim h6 w-100 text-left"
            type="button"
            data-toggle="collapse"
            data-target="#promoCodeContainer"
            aria-expanded="false"
          >
            <span class="caret d-inline-block"></span> Promo code
          </button>
          <div id="promoCodeContainer" class="collapse">
            <div class="plan-selected d-flex flex-column align-items-start justify-content-start">
              <div class="plan-selected-option mt-1">
                <div class="font-weight-light">
                  If you have a promo code, enter it below.
                </div>
              </div>
            </div>
            <div class="form-group">
              <div class="input-group">
                <input
                  type="text"
                  class="form-control form-control-sm"
                  id="promoCode"
                  placeholder="Coupon code"
                  value={promoCode}
                  onChange={(e) => setPromoCode(e.currentTarget.value)}
                />
                <button
                  class="btn btn-sky btn-sm ml-2 px-2"
                  onClick={() => {
                    checkPromoCode(promoCode)
                  }}
                >
                  Apply{' '}
                  {promoCodeLoading ? (
                    <FaSpinner
                      size={15}
                      className="icon-spin"
                      style={{ marginLeft: '4px' }}
                    />
                  ) : null}
                </button>
              </div>
            </div>
            {promoCodeStatus ? (
              <div
                class={`font-weight-bold ${
                  promoCodeStatus.mode === 'success' ? 'green' : 'red'
                }-1 small`}
              >
                {promoCodeStatus.message}
              </div>
            ) : null}
          </div> */}
          <hr size={1} />

          {/* <div class="row mx-1">
            <div class="form-group d-flex flex-row w-100">
              <div class="mr-2" style={{ minWidth: '50%' }}>
                <label>Expiry</label>
                <CardExpiryElement
                  options={CARD_OPTIONS}
                  className="form-control stripe-card-element-override"
                  onChange={(e) => {
                    console.log('CardExpiryElement - e')
                    console.log(e)
                    if (e.error) {
                      setCardExpirationErrorMessage(e.error.message)
                    } else {
                      setCardExpirationErrorMessage('')
                    }
                  }}
                />
                {cardExpirationErrorMessage ? (
                  <div class="small red-1 line-height-2">
                    {cardExpirationErrorMessage}
                  </div>
                ) : null}
              </div>

              <div class="mr-2" style={{ minWidth: '50%' }}>
                <label>CVC</label>
                <CardCvcElement
                  options={CARD_OPTIONS}
                  className="form-control stripe-card-element-override"
                  onChange={(e) => {
                    console.log('CardCvcElement - e')
                    console.log(e)
                    if (e.error) {
                      setCardCVCErrorMessage(e.error.message)
                    } else {
                      setCardCVCErrorMessage('')
                    }
                  }}
                />
                {cardCVCErrorMessage ? (
                  <div class="small red-1 line-height-2">
                    {cardCVCErrorMessage}
                  </div>
                ) : null}
              </div>
            </div>
          </div> */}
        </div>
      </div>
    </>
  )
}

export default Snippet
