import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'

import { Link } from 'gatsby'

//Packages
import Helmet from 'react-helmet'
import { navigate } from '@reach/router'
import parse from 'url-parse'

//Actions
import {
  getStripeCustomerID,
  createSubscription,
  createPayment,
  retryInvoice,
  retrievePromoCode,
  retrievePaymentIntentStatus,
  updateFulfillEmail,
  updatePaymentIntentId,
  updateEmailInput,
} from '../../actions/stripeManager'
import {
  updatePurchasePositionNumber,
  updatePurchaseLoading,
  updatePurchasePlan,
  updatePurchaseErrors,
  setProvisionedUsersNumber,
} from '../../actions/updatePurchase'

//Packages
import { loadStripe } from '@stripe/stripe-js'
import {
  CardElement,
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
  Elements,
  useStripe,
  useElements,
  PaymentRequestButtonElement,
} from '@stripe/react-stripe-js'

//Components
import Layout from '../../components/layout'
import PurchasePlans from '../../components/purchase/purchasePlans'
import PurchasePosition from '../../components/purchase/purchasePosition'
import PurchaseUserDetails from '../../components/purchase/purchaseUserDetails'
import PurchasePaymentDetails from '../../components/purchase/purchasePaymentDetails'
import PurchaseSuccess from '../../components/purchase/purchaseSuccess'
import PurchaseButtons from '../../components/purchase/purchaseButtons'

//Icons
import { FaArrowRight } from 'react-icons/fa'

//Redux
const mapStateToProps = ({
  purchasePlan,
  purchaseErrors,
  isLoadingPurchase,
  purchasePositionNumber,
  provisionedUsersNumber,
  fulfillOrderEmail,
  paymentIntentId,
  emailInput,
}) => {
  return {
    purchasePlan,
    purchaseErrors,
    isLoadingPurchase,
    purchasePositionNumber,
    provisionedUsersNumber,
    fulfillOrderEmail,
    paymentIntentId,
    emailInput,
  }
}
const mapDispatchToProps = (dispatch) => ({
  updatePurchaseLoading: (bool) => {
    dispatch(updatePurchaseLoading(bool))
  },
  updatePurchasePositionNumber: (pos) => {
    dispatch(updatePurchasePositionNumber(pos))
  },
  updatePurchaseErrors: (error) => {
    dispatch(updatePurchaseErrors(error))
  },
  updatePurchasePlan: (plan) => {
    dispatch(updatePurchasePlan(plan))
  },
  setProvisionedUsersNumber: (num) => {
    dispatch(setProvisionedUsersNumber(num))
  },
  updateFulfillEmail: (email) => {
    dispatch(updateFulfillEmail(email))
  },
  updatePaymentIntentId: (id) => {
    dispatch(updatePaymentIntentId(id))
  },
  updateEmailInput: (email) => {
    dispatch(updateEmailInput(email))
  },
  // createPayment: (
  //   customerID,
  //   paymentMethodID,
  //   provisioner,
  //   pageContext,
  //   mode
  // ) => {
  //   dispatch(
  //     createPayment(customerID, paymentMethodID, provisioner, pageContext, mode)
  //   )
  // },
})

const stripePromise = loadStripe(process.env.STRIPE_PUB_KEY || '')
const CARD_OPTIONS = {
  iconStyle: 'solid',
  style: {
    base: {
      iconColor: '#666666',
      color: '#333333',
      fontWeight: 500,
      fontFamily: 'Open Sans, sans-serif',
      fontSize: '16px',
      fontSmoothing: 'antialiased',
      ':-webkit-autofill': {
        color: '#333333',
      },
      '::placeholder': {
        color: '#636c72',
      },
    },
    invalid: {
      iconColor: '#da4748',
      color: '#da4748',
    },
  },
}

const Upgrade = (props) => {
  const {
    purchasePositionNumber,
    updatePurchasePositionNumber,
    updatePurchaseLoading,
    isLoadingPurchase,
    purchasePlan,
    updatePurchasePlan,
    purchaseErrors,
    updatePurchaseErrors,
    setProvisionedUsersNumber,
    provisionedUsersNumber,
    promoCodeLoading,
    // createPayment,
    updateFulfillEmail,
    fulfillOrderEmail,
    updatePaymentIntentId,
    paymentIntentId,
    emailInput,
    updateEmailInput,
  } = props

  return (
    <>
      {' '}
      <Elements stripe={stripePromise}>
        <CheckoutForm
          purchasePositionNumber={purchasePositionNumber}
          updatePurchasePositionNumber={updatePurchasePositionNumber}
          updatePurchaseLoading={updatePurchaseLoading}
          isLoadingPurchase={isLoadingPurchase}
          purchasePlan={purchasePlan}
          updatePurchasePlan={updatePurchasePlan}
          purchaseErrors={purchaseErrors}
          updatePurchaseErrors={updatePurchaseErrors}
          setProvisionedUsersNumber={setProvisionedUsersNumber}
          provisionedUsersNumber={provisionedUsersNumber}
          // createPayment={createPayment}
          updateFulfillEmail={updateFulfillEmail}
          fulfillOrderEmail={fulfillOrderEmail}
          updatePaymentIntentId={updatePaymentIntentId}
          paymentIntentId={paymentIntentId}
          emailInput={emailInput}
          updateEmailInput={updateEmailInput}
        />
      </Elements>
    </>
    // <Layout>
    //   <div class="container container-purchase">
    //     <div class="row">
    //       <div class="col-md-12">
    //         {/* <h1>Upgrade now</h1> */}
    //         <Elements stripe={stripePromise}>
    //           <CheckoutForm
    //             purchasePositionNumber={purchasePositionNumber}
    //             updatePurchasePositionNumber={updatePurchasePositionNumber}
    //             updatePurchaseLoading={updatePurchaseLoading}
    //             isLoadingPurchase={isLoadingPurchase}
    //             purchasePlan={purchasePlan}
    //             updatePurchasePlan={updatePurchasePlan}
    //             purchaseErrors={purchaseErrors}
    //             updatePurchaseErrors={updatePurchaseErrors}
    //             setProvisionedUsersNumber={setProvisionedUsersNumber}
    //             provisionedUsersNumber={provisionedUsersNumber}
    //           />
    //         </Elements>
    //       </div>
    //     </div>
    //   </div>
    // </Layout>
  )
}
const CheckoutForm = (props) => {
  // Include these hooks:
  const stripe = useStripe()
  const elements = useElements()
  const [nameInput, setNameInput] = useState('')
  // const [emailInput, setEmailInput] = useState('')
  const [firstNameInput, setFirstNameInput] = useState('')
  const [lastNameInput, setLastNameInput] = useState('')
  const [companyNameInput, setCompanyNameInput] = useState('')
  const [provideCCNowInput, setProvideCCNowInput] = useState(false)
  // const [purchasePositionNumber, updatePurchasePositionNumber] = useState(1)
  // const [provisionedUsersNumber, setProvisionedUsersNumber] = useState(1)
  const [totalPurchaseAmount, setTotalPurchaseAmount] = useState(0)
  const [errorMessages, setErrorMessages] = useState([])
  const [retry, setRetry] = useState(
    typeof window !== 'undefined'
      ? !!localStorage.getItem('invoice_retry')
      : false
  )

  const [cardErrorMessage, setCardErrorMessage] = useState('')
  const [cardNameErrorMessage, setCardNameErrorMessage] = useState('')
  const [cardExpirationErrorMessage, setCardExpirationErrorMessage] =
    useState('')
  const [cardCVCErrorMessage, setCardCVCErrorMessage] = useState('')
  const [referralCodeInput, setReferralCodeInput] = useState('')
  const [referralCodeRawInput, setReferralCodeRawInput] = useState('')
  const [outboundReferralCode, setOutboundReferralCode] = useState('')
  const [copiedToClipboard, setCopiedToClipboard] = useState(false)
  const [promoCode, setPromoCode] = useState('')
  const [promoCodeStatus, setPromoCodeStatus] = useState('')
  const [promoCodeLoading, setPromoCodeLoading] = useState(false)

  const [pageContext, setPageContext] = useState('')
  const [pagePathname, setPagePathname] = useState('')
  // const [paymentIntentId, setPaymentIntentId] = useState('')
  const [paymentIntentCustomerId, setPaymentIntentCustomerId] = useState('')

  const [paymentRequest, setPaymentRequest] = useState(null)

  const {
    purchasePositionNumber,
    updatePurchasePositionNumber,
    updatePurchaseLoading,
    isLoadingPurchase,
    purchasePlan,
    updatePurchasePlan,
    purchaseErrors,
    updatePurchaseErrors,
    setProvisionedUsersNumber,
    provisionedUsersNumber,
    fulfillOrderEmail,
    // createPayment,
    updateFulfillEmail,
    updatePaymentIntentId,
    paymentIntentId,
    emailInput,
    updateEmailInput,
  } = props

  console.log('index - provisionedUsersNumber')
  console.log(provisionedUsersNumber)

  const fulfillOrder = async (id, paymentMethodID) => {
    const provisioner = { id }
    try {
      const paymentIntent = await createPayment(
        '',
        paymentMethodID,
        provisioner,
        pageContext,
        'fulfill'
      )
      console.log(
        'actions - purchase - handleSubmitPayment - paymentIntent - 3'
      )
      console.log(paymentIntent)
      if (paymentIntent && paymentIntent.data && paymentIntent.data.fulfill) {
        console.log(
          'actions - purchase - handleSubmitPayment - fulfill - start'
        )
        updateFulfillEmail(paymentIntent.data.fulfill)
      } else if (paymentIntent && paymentIntent.error) {
        console.log(
          'actions - purchase - handleSubmitPayment - fulfill - paymentIntent.error'
        )
        console.log(paymentIntent.error)

        updatePurchaseErrors({ message: paymentIntent.error })
      }
    } catch (error) {
      console.log(
        'actions - purchase - handleSubmitPayment - updatePurchaseErrors - 4'
      )
      console.log(error)
      updatePurchaseErrors({ message: error.response.data })
    }
  }

  useEffect(() => {
    updatePurchaseErrors({})
    updatePurchasePlan('guide-passadicos')
    updatePaymentIntentId('')
    setPaymentIntentCustomerId('')
    const url = parse(window.location.href, true)

    console.log('parse- url')
    console.log(url)

    if (url) {
      setPageContext(url.href)
      setPagePathname(url.pathname)
    }
    if (url && url.query && url.query.payment_intent && url.query.id) {
      updatePaymentIntentId(url.query.payment_intent)
      setPaymentIntentCustomerId(url.query.id)
    }
    if (url && url.query && url.query.payment_intent && url.query.id) {
      // if (paymentIntentId && paymentIntentCustomerId) {
      const fetchPaymentIntentStatus = async () => {
        const paymentIntentStatus = await retrievePaymentIntentStatus(
          url.query.payment_intent
        )
        console.log('paymentIntentStatus')
        console.log(paymentIntentStatus)
        return paymentIntentStatus
      }

      fetchPaymentIntentStatus()
        .then((data) => {
          console.log('fetchPaymentIntentStatus - data - fulfill')
          console.log(data)
          updatePurchasePositionNumber(3)
          fulfillOrder(url.query.id, url.query.payment_intent)
          if (typeof window !== `undefined`) {
            window.scrollTo(0, 0)
          }
        })
        .catch((err) => {
          console.log('fetchPaymentIntentStatus - err')
          console.log(err)
        })
    }
  }, [])

  const handlePaymentButtonClicked = (event) => {
    console.log('handlePaymentButtonClicked - start')

    paymentRequest.on('paymentmethod', handlePaymentMethodReceived)
    paymentRequest.on('cancel', () => {
      paymentRequest.off('paymentmethod')
    })
    return
  }

  const handlePaymentMethodReceived = async (event) => {
    // Send the cart details and payment details to our function.
    console.log('handlePaymentMethodReceived - event')
    console.log(event)
    const paymentDetails = {
      payment_method: event.paymentMethod.id,
      shipping: {
        name: event.shippingAddress.recipient,
        phone: event.shippingAddress.phone,
        address: {
          line1: event.shippingAddress.addressLine[0],
          city: event.shippingAddress.city,
          postal_code: event.shippingAddress.postalCode,
          state: event.shippingAddress.region,
          country: event.shippingAddress.country,
        },
      },
    }

    const firstName = event.payerName.split(' ')[0]
    console.log('firstName')
    console.log(firstName)
    const lastName = event.payerName.split(' ').slice(1).join(' ')

    console.log('lastName')
    console.log(lastName)
    const customerId = await getStripeCustomerID(
      event.payerEmail,
      firstName,
      lastName,
      ''
    )

    console.log('customerId')
    console.log(customerId)
    const provisioner = {
      email: event.payerEmail,
      domainname: '',
      // domainname: emailInput.split('@')[1],
      companyname: '',
      sms: false, //BOOL
      accessPlan: 'guide-passadicos',
      accessPlanmodules: [],
      accessRole: 'admin',
      fname: firstName,
      lname: lastName,
      userQty: 1,
      inboundReferral: '',
    }
    console.log(
      'actions - purchase - handlePaymentMethodReceived - provisioner'
    )
    console.log(provisioner)

    const paymentIntent = await createPayment(
      customerId.id,
      event.paymentMethod.id, //paymentId
      provisioner,
      pageContext,
      'newWithPaymentRequest'
    )

    console.log(
      'actions - purchase - handlePaymentMethodReceived - paymentIntent'
    )
    console.log(paymentIntent)
    if (paymentIntent.error) {
      // Report to the browser that the payment failed.
      console.log(
        'actions - purchase - handlePaymentMethodReceived - paymentIntent.error'
      )
      console.log(paymentIntent.error)
      event.complete('fail')
    } else {
      // Report to the browser that the confirmation was successful, prompting
      // it to close the browser payment method collection interface.
      event.complete('success')
      // Let Stripe.js handle the rest of the payment flow, including 3D Secure if needed.
      const paymentConfirm = await stripe.confirmCardPayment(
        paymentIntent.data.client_secret
      )
      console.log(
        'actions - purchase - handlePaymentMethodReceived - paymentConfirm'
      )
      console.log(paymentConfirm)
      if (
        paymentConfirm &&
        paymentConfirm.paymentIntent &&
        paymentConfirm.paymentIntent.status === 'succeeded'
      ) {
        updatePurchasePositionNumber(3)

        fulfillOrder(
          paymentIntent.data.id,
          paymentConfirm.paymentIntent.payment_method
        )
        if (typeof window !== `undefined`) {
          window.scrollTo(0, 0)
        }
      }
      // if (error) {
      //   console.log(error)
      //   return
      // }
      // if (paymentIntent.status === 'succeeded') {
      //   history.push('/success')
      // } else {
      //   console.warn(
      //     `Unexpected status: ${paymentIntent.status} for ${paymentIntent}`
      //   )
      // }
    }
  }

  useEffect(() => {
    if (stripe) {
      console.log('paymentRequest - pre - usingStripe')
      const pr = stripe.paymentRequest({
        country: 'US',
        currency: 'usd',
        total: {
          label: 'Passadiços Guide',
          amount: 999,
        },
        requestPayerName: true,
        requestPayerEmail: true,
        // requestShipping: true,
      })
      console.log('paymentRequest - pr')
      console.log(pr)
      // Check the availability of the Payment Request API.
      pr.canMakePayment().then((result) => {
        if (result) {
          setPaymentRequest(pr)
        }
      })
      if (paymentRequest) {
        paymentRequest.on('paymentmethod', async (ev) => {
          // Confirm the PaymentIntent without handling potential next actions (yet).
          const { paymentIntent, error: confirmError } =
            await stripe.confirmCardPayment(
              paymentIntent.client_secret,
              { payment_method: ev.paymentMethod.id },
              { handleActions: false }
            )

          console.log('paymentIntent')
          console.log(paymentIntent)

          if (confirmError) {
            // Report to the browser that the payment failed, prompting it to
            // re-show the payment interface, or show an error message and close
            // the payment interface.
            ev.complete('fail')
          } else {
            // Report to the browser that the confirmation was successful, prompting
            // it to close the browser payment method collection interface.
            ev.complete('success')
            // Check if the PaymentIntent requires any actions and, if so, let Stripe.js
            // handle the flow. If using an API version older than "2019-02-11"
            // instead check for: `paymentIntent.status === "requires_source_action"`.
            if (paymentIntent.status === 'requires_action') {
              // Let Stripe.js handle the rest of the payment flow.
              const { error } = await stripe.confirmCardPayment(
                paymentIntent.client_secret
              )
              if (error) {
                // The payment failed -- ask your customer for a new payment method.
              } else {
                // The payment has succeeded.
              }
            } else {
              // The payment has succeeded.
            }
          }
        })
      } //end if paymentRequest
    }
  }, [stripe])

  const checkPromoCode = async (promoCodeVal) => {
    setPromoCodeLoading(true)
    setPromoCodeStatus({})

    try {
      const promoCode = await retrievePromoCode(promoCodeVal)

      console.log('promoCode')
      console.log(promoCode)
      if (promoCode && promoCode.error) {
        setPromoCodeStatus({ mode: 'error', message: promoCode.error })
        // throw Error(promoCode.error)
      }

      if (promoCode && promoCode.name && promoCode.id) {
        if (promoCode.name.toLowerCase().includes(purchasePlan)) {
          setPromoCodeStatus({
            mode: 'success',
            message: `Coupon applied: ${promoCode.name} / $${
              promoCode.amount_off / 100
            } discount/mo`,
            id: promoCode.id,
          })
        } else {
          setPromoCodeStatus({
            mode: 'error',
            message: `This coupon [${promoCode.name} / $${
              promoCode.amount_off / 100
            } discount/mo] is not applicable for ${purchasePlan.toUpperCase()} plans.`,
            id: promoCode.id,
          })
        }
      }
      setPromoCodeLoading(false)
    } catch (error) {
      console.log('checkPromoCode - error')
      console.error(error)
      setPromoCodeStatus({ mode: 'error', message: error })
      setPromoCodeLoading(false)
    }
  }

  const handleSubmitPayment = async () => {
    if (!stripe || !elements) {
      return
    }
    try {
      console.log('actions - handleSubmitPayment - provideCCNowInput')
      console.log(provideCCNowInput)

      updatePurchaseErrors({})
      updatePurchaseLoading(true)

      console.log('actions - handleSubmitPayment - pre - createPaymentMethod')

      let paymentId = null

      // if (provideCCNowInput) {
      const { error, paymentMethod } = await stripe.createPaymentMethod({
        type: 'card',
        card: elements.getElement(CardElement),
        billing_details: {
          name: nameInput,
          email: emailInput,
        },
      })

      console.log('actions - handleSubmitPayment - paymentMethod')
      console.log(paymentMethod)

      if (error || !paymentMethod) {
        updatePurchaseErrors(
          error && error.message
            ? { message: error.message }
            : {
                message:
                  'We encountered an error processing your information. Please contact support@sidepath.xyz for help.',
              }
        )
        throw Error(
          error && error.message ? error.message : 'Something is not right...'
        )
      }
      paymentId = paymentMethod.id

      console.log('actions - purchase - handleSubmitPayment - paymentId')
      console.log(paymentId)

      // } else {
      //   updatePurchaseErrors("Couldn't set your payment method")
      // }
      const customerId = await getStripeCustomerID(
        emailInput,
        firstNameInput,
        lastNameInput,
        companyNameInput
      )

      if (!customerId) {
        updatePurchaseErrors({
          message:
            'We encountered an error creating your customer record. Please contact support@sidepath.xyz for help.',
        })

        throw Error('Could not identify customer')
      } else if (customerId && customerId.mode === 'existing') {
        updatePurchaseErrors({
          message:
            'This email address already has a Sidepath account. Please contact support@sidepath.xyz for help.',
        })

        throw Error('Customer already exists')
      }
      // if (!provisionedUsersNumber || provisionedUsersNumber === 0) {
      //   updatePurchaseErrors({message:'Please enter the number of users in Step 1'})

      //   throw Error('Could not get user number')
      // }
      console.log('actions - purchase - handleSubmitPayment - customerId')
      console.log(customerId)

      const provisioner = {
        email: emailInput,
        domainname: emailInput.split('@')[1],
        companyname: companyNameInput,
        sms: false, //BOOL
        accessPlan: purchasePlan,
        accessPlanmodules: [],
        accessRole: 'admin',
        fname: firstNameInput,
        lname: lastNameInput,
        userQty: provisionedUsersNumber,
        inboundReferral: referralCodeRawInput,
      }
      if (
        promoCode &&
        promoCodeStatus &&
        promoCodeStatus.id &&
        promoCodeStatus.mode === 'success'
      ) {
        provisioner.promoId = promoCodeStatus.id
      }
      console.log('actions - purchase - handleSubmitPayment - provisioner')
      console.log(provisioner)

      const paymentIntent = await createPayment(
        customerId.id,
        paymentId,
        provisioner,
        pageContext,
        'new'
      )
      console.log(
        'actions - purchase - handleSubmitPayment - paymentIntent - 2'
      )
      console.log(paymentIntent)

      if (
        paymentIntent &&
        paymentIntent.data &&
        paymentIntent.data.referralCode
      ) {
        console.log('paymentIntent.data.referralCode')
        console.log(paymentIntent.data.referralCode)
        console.log(
          'actions - purchase - handleSubmitPayment - paymentIntent.data.referralCode'
        )
        console.log(paymentIntent.data.referralCode)

        setOutboundReferralCode(paymentIntent.data.referralCode)
      }
      if (paymentIntent && paymentIntent.error) {
        updatePurchaseErrors({
          message: paymentIntent.error,
        })
        throw Error(paymentIntent.error)
      } else if (
        paymentIntent &&
        paymentIntent.data &&
        paymentIntent.data.redirect
      ) {
        navigate(paymentIntent.data.redirect)
        return
      } else if (
        paymentIntent &&
        paymentIntent.data &&
        paymentIntent.data.latest_invoice &&
        paymentIntent.data.latest_invoice.payment_intent &&
        paymentIntent.data.latest_invoice.payment_intent.status ===
          'requires_payment_method'
      ) {
        setRetry(true)
        localStorage.setItem(
          'latest_invoice_id',
          paymentIntent.latest_invoice.id
        )
        updatePurchaseErrors({
          message:
            'Your card was declined. Please try again or with another card.',
        })
        throw Error(
          'Your card was declined. Please try again or with another card'
        )
      } else if (
        paymentIntent &&
        paymentIntent.data &&
        (paymentIntent.data.status === 'active' ||
          paymentIntent.data.status === 'trialing')
      ) {
        //Success subscription
        updatePurchaseLoading(false)
        updatePurchasePositionNumber(3)
        if (typeof window !== `undefined`) {
          window.scrollTo(0, 0)
        }
      } else if (paymentIntent && paymentIntent.status === 200) {
        //Success
        //continue
        updatePurchaseLoading(false)
        updatePurchasePositionNumber(3)
        if (typeof window !== `undefined`) {
          window.scrollTo(0, 0)
        }
      } else if (
        paymentIntent &&
        paymentIntent.data &&
        paymentIntent.data.status &&
        paymentIntent.data.status !== 'active' &&
        paymentIntent.data.status !== 'trialing'
      ) {
        console.log('throwing CC error')
        updatePurchaseErrors({ message: 'Could not process credit card.' })
        throw Error('Could not process credit card.')
      }

      if (paymentIntent && paymentIntent.error) {
        updatePurchaseErrors({ message: paymentIntent.error })
        throw Error(paymentIntent.error)
      }
    } catch (error) {
      updatePurchaseLoading(false)
      console.error(error)
      // Let the user know that something went wrong here...
    }
  }
  const handleRetryPayment = async () => {
    if (!stripe || !elements) {
      return
    }
    const invoiceID = localStorage.getItem('latest_invoice_id')
    try {
      if (!invoiceID) {
        updatePurchaseErrors({
          message:
            'Could not process credit card. Please refresh and try again.',
        })

        throw Error(
          'Could not process credit card. Please refresh and try again.'
        )
      }
      const { error, paymentMethod } = await stripe.createPaymentMethod({
        type: 'card',
        card: elements.getElement(CardNumberElement),
        billing_details: {
          name: nameInput,
        },
      })
      if (error || !paymentMethod) {
        updatePurchaseErrors({
          message:
            'We encountered an error processing your information. Please contact support@sidepath.xyz for help.',
        })
        throw Error(error?.message || 'Something is not right...')
      }
      const customerId = await getStripeCustomerID()
      if (customerId.error) {
        updatePurchaseErrors({ message: customerId.error })
        throw Error('Error in identifying customer')
      }
      if (!customerId) {
        updatePurchaseErrors({
          message:
            "We couldn't get your customer record. Please contact support@sidepath.xyz for help.",
        })
        throw Error('Could not identify customer')
      }
      console.log('actions - purchase - handleRetryPayment - customerId')
      console.log(customerId)

      const paymentId = paymentMethod.id
      await retryInvoice(customerId, paymentId, invoiceID)
      localStorage.removeItem('latest_invoice_id')
    } catch (error) {
      console.error(error)
      // Let the user know that something went wrong here...
    }
  }
  const buttonAction = retry ? handleRetryPayment : handleSubmitPayment
  console.log('purchaseErrors')
  console.log(purchaseErrors)

  const validateEmail = (email) => {
    var mailformat =
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
    if (email.match(mailformat)) {
      return true
    } else {
      return false
    }
  }

  let priceVal
  switch (purchasePlan) {
    case 'standard':
      priceVal = 19.99
      break
    // case 'plus':
    //   priceVal = 25
    //   break
    case 'pro':
      priceVal = 39.99
      break
    case 'guide-passadicos':
    case 'guide-zion':
      priceVal = 9.99
      break
    default:
      priceVal = NaN
  }

  return (
    <>
      {/* <Helmet
        title="Sidepath - Get Started"
        meta={[
          {
            name: 'description',
            content: 'Get started with Sidepath',
          },
        ]}
      /> */}
      {/* <div
        class={`fade-in ${purchasePositionNumber < 3 ? 'd-block' : 'd-none'}`}
      >
        <PurchasePosition
          purchasePositionNumber={purchasePositionNumber}
          updatePurchasePositionNumber={updatePurchasePositionNumber}
        />
        <div class="row mt-1 d-flex align-items-center justify-content-center">
          <div class="col-md-7 col-lg-5">
            <h4>Get started with Sidepath</h4>
            <div class="d-flex flex-column">
              <div class="small gray-1">Free 7-day trial, cancel anytime</div>
              <Link class="sky-1 small  text-underline" to="/about/pricing/">
                See all plans
                <FaArrowRight class="btn-arrow" />
              </Link>
            </div>
          </div>
        </div>
      </div> */}
      {paymentIntentId && purchaseErrors ? (
        <>
          {purchaseErrors && purchaseErrors.message ? (
            <div class="row d-flex align-items-center justify-content-center mt-2">
              <div class="">
                <div class="d-flex flex-column bg-red-5 rounded-corners p-3">
                  {purchaseErrors ? (
                    <>
                      <div class="red-1 small">{purchaseErrors.message}</div>
                      <a
                        className="small error-link"
                        onClick={() => {
                          window.location = pagePathname
                        }}
                      >
                        Purchase again
                      </a>
                    </>
                  ) : null}
                </div>
              </div>
            </div>
          ) : null}
        </>
      ) : null}
      {Object.keys(purchaseErrors).length === 0 &&
      (purchasePositionNumber === 3 || fulfillOrderEmail) ? (
        <div class={`fade-in`}>
          <PurchaseSuccess
            emailInput={emailInput}
            fulfillOrderEmail={fulfillOrderEmail}
            provisionedUsersNumber={provisionedUsersNumber}
            outboundReferralCode={outboundReferralCode}
            copiedToClipboard={copiedToClipboard}
            setCopiedToClipboard={setCopiedToClipboard}
          />
        </div>
      ) : null}
      {purchasePositionNumber === 1 && !paymentIntentId ? (
        <div class={`fade-in`}>
          <div
          // class="pb-3"
          >
            {/* <div
          class={`fade-in ${
            purchasePositionNumber === 1 ? 'd-block' : 'd-none'
          }`}
        >
          <PurchasePlans
            provisionedUsersNumber={provisionedUsersNumber}
            totalPurchaseAmount={totalPurchaseAmount}
            setTotalPurchaseAmount={setTotalPurchaseAmount}
            setProvisionedUsersNumber={setProvisionedUsersNumber}
            updatePurchasePositionNumber={updatePurchasePositionNumber}
            errorMessages={errorMessages}
            setErrorMessages={setErrorMessages}
            purchaseErrors={purchaseErrors}
            updatePurchaseErrors={updatePurchaseErrors}
          />
        </div> */}
            {/* <div
        class={`fade-in ${
          purchasePositionNumber === 1 ? 'd-block' : 'd-none'
        }`}
        > */}

            <PurchaseUserDetails
              purchasePlan={purchasePlan}
              emailInput={emailInput}
              updateEmailInput={updateEmailInput}
              firstNameInput={firstNameInput}
              setFirstNameInput={setFirstNameInput}
              lastNameInput={lastNameInput}
              setLastNameInput={setLastNameInput}
              companyNameInput={companyNameInput}
              setCompanyNameInput={setCompanyNameInput}
              updatePurchasePositionNumber={updatePurchasePositionNumber}
              errorMessages={errorMessages}
              setErrorMessages={setErrorMessages}
              purchaseErrors={purchaseErrors}
              updatePurchaseErrors={updatePurchaseErrors}
              provisionedUsersNumber={provisionedUsersNumber}
              setProvisionedUsersNumber={setProvisionedUsersNumber}
              updatePurchasePlan={updatePurchasePlan}
              priceVal={priceVal}
            />
            {/* </div> */}

            <div
            // class={`fade-in ${
            //   purchasePositionNumber === 2 ? 'd-block' : 'd-none'
            // }`}
            >
              <PurchasePaymentDetails
                purchasePlan={purchasePlan}
                nameInput={nameInput}
                setNameInput={setNameInput}
                CardElement={CardElement}
                CardNumberElement={CardNumberElement}
                CardExpiryElement={CardExpiryElement}
                CardCvcElement={CardCvcElement}
                CARD_OPTIONS={CARD_OPTIONS}
                provisionedUsersNumber={provisionedUsersNumber}
                totalPurchaseAmount={totalPurchaseAmount}
                emailInput={emailInput}
                firstNameInput={firstNameInput}
                lastNameInput={lastNameInput}
                companyNameInput={companyNameInput}
                updatePurchasePositionNumber={updatePurchasePositionNumber}
                buttonAction={buttonAction}
                errorMessages={errorMessages}
                setErrorMessages={setErrorMessages}
                purchaseErrors={purchaseErrors}
                updatePurchaseErrors={updatePurchaseErrors}
                cardErrorMessage={cardErrorMessage}
                cardNameErrorMessage={cardNameErrorMessage}
                cardExpirationErrorMessage={cardExpirationErrorMessage}
                cardCVCErrorMessage={cardCVCErrorMessage}
                setCardErrorMessage={setCardErrorMessage}
                setCardNameErrorMessage={setCardNameErrorMessage}
                setCardExpirationErrorMessage={setCardExpirationErrorMessage}
                setCardCVCErrorMessage={setCardCVCErrorMessage}
                provideCCNowInput={provideCCNowInput}
                setProvideCCNowInput={setProvideCCNowInput}
                referralCodeInput={referralCodeInput}
                setReferralCodeInput={setReferralCodeInput}
                referralCodeRawInput={referralCodeRawInput}
                setReferralCodeRawInput={setReferralCodeRawInput}
                promoCode={promoCode}
                setPromoCode={setPromoCode}
                promoCodeStatus={promoCodeStatus}
                setPromoCodeStatus={setPromoCodeStatus}
                checkPromoCode={checkPromoCode}
                promoCodeLoading={promoCodeLoading}
                priceVal={priceVal}
              />
            </div>

            {(purchasePositionNumber < 3 &&
              Object.values(purchaseErrors).some((prop) => prop === true)) ||
            purchaseErrors.message ? (
              <div class="row d-flex align-items-center justify-content-center mt-2">
                <div class="">
                  <div class="d-flex flex-column bg-red-5 rounded-corners p-3">
                    {purchaseErrors.message ? (
                      <div class="red-1 small">{purchaseErrors.message}</div>
                    ) : null}
                    {purchaseErrors.emailValid ? (
                      <div class="red-1 small">
                        Your email address appears to be invalid
                      </div>
                    ) : null}
                    {purchaseErrors.fname ||
                    purchaseErrors.lname ||
                    purchaseErrors.companyName ||
                    purchaseErrors.email ? (
                      <div class="red-1 small">
                        Please fill out the details below
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
            ) : null}

            <PurchaseButtons
              isLoadingPurchase={isLoadingPurchase}
              buttonAction={buttonAction}
              purchasePositionNumber={purchasePositionNumber}
              nameInput={nameInput}
              setNameInput={setNameInput}
              firstNameInput={firstNameInput}
              lastNameInput={lastNameInput}
              companyNameInput={companyNameInput}
              emailInput={emailInput}
              updatePurchaseErrors={updatePurchaseErrors}
              updatePurchasePositionNumber={updatePurchasePositionNumber}
              validateEmail={validateEmail}
              cardErrorMessage={cardErrorMessage}
              cardNameErrorMessage={cardNameErrorMessage}
              cardExpirationErrorMessage={cardExpirationErrorMessage}
              cardCVCErrorMessage={cardCVCErrorMessage}
              setCardErrorMessage={setCardErrorMessage}
              setCardNameErrorMessage={setCardNameErrorMessage}
              setCardExpirationErrorMessage={setCardExpirationErrorMessage}
              setCardCVCErrorMessage={setCardCVCErrorMessage}
              purchasePlan={purchasePlan}
              provisionedUsersNumber={provisionedUsersNumber}
              provideCCNowInput={provideCCNowInput}
              referralCodeRawInput={referralCodeRawInput}
              PaymentRequestButtonElement={PaymentRequestButtonElement}
              paymentRequest={paymentRequest}
              handlePaymentButtonClicked={handlePaymentButtonClicked}
            />
          </div>
        </div>
      ) : null}
    </>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(Upgrade)
