import Amplify from '@aws-amplify/core'
import API from '@aws-amplify/api'

import { amplifyconfig } from '../utils/config'
import { updatePurchaseErrors } from './updatePurchase'

const axios = require('axios')

Amplify.configure(amplifyconfig)

let apiName = 'Picopulsemkt'

export function updateEmailInput(email) {
  return (dispatch) => {
    console.log('actions - stripeManager - updateEmailInput')
    dispatch({
      type: 'EMAIL_INPUT_UPDATE',
      data: email,
    })
  }
}
export function updateUserInDB(email) {
  console.log('update user in DB')
}
// export function createCustomer2(email) {
//   return new Promise((resolve) => {
//     console.log('createCustomer2 - email')
//     console.log(createCustomer2)
//     resolve({ id: '123999' })
//   })
// }
export async function createCustomer(email, fname, lname, companyname) {
  // export const createCustomer = async (email) => {
  console.log('actions - createCustomer - start')
  console.log('email')
  console.log(email)
  console.log('fname')
  console.log(fname)
  console.log('lname')
  console.log(lname)
  console.log('companyname')
  console.log(companyname)
  // return async function createCustomerDispatch(dispatch) {
  // return async (dispatch) => {
  try {
    // Retrieve email and username of the currently logged in user.
    // getUserFromDB() is *your* implemention of getting user info from the DB
    // const { email, username } = getUserFromDB()
    if (!email) {
      throw Error('Email not found.')
    }
    if (!fname || !lname) {
      throw Error('Customer name not found.')
    }
    // const request = await fetch(
    //   `${process.env.GATSBY_API_URL_MKT}stripe/create-customer`,
    //   {
    //     method: 'POST',
    //     body: JSON.stringify({
    //       email,
    //       username,
    //     }),
    //   }
    // )
    // let options = {
    //   body: {
    //     email,
    //     username: email,
    //   },
    // }
    let options = {
      email,
      fname,
      lname,
      companyname,
    }

    console.log('actions - createCustomer - pre - options')
    console.log(options)

    // const request = await API.post(apiName, 'stripe/create-customer', options)
    const request = await axios.post(
      `${process.env.GATSBY_API_URL_MKT}stripe/create-customer`,
      options
    )

    console.log('actions - createCustomer - request')
    console.log(request)

    const customer = request
    // const customer = { data: '12345' }
    // const customer = await request.json()

    console.log('actions - createCustomer - customer')
    console.log(customer)
    // TODO
    // Update your user in DB to store the customerID
    // updateUserInDB() is *your* implementation of updating a user in the DB
    // updateUserInDB({ customerID: customer.id })
    return customer.data
  } catch (error) {
    console.log('Failed to create customer')
    console.log(error)
    console.log('error.message')
    console.log(error.message)
    console.log('error.response')
    console.log(error.response)
    return { error: error.response.data }

    // return dispatch(updatePurchaseErrors(error.response.data))
    // return null
  }
  // }
}

export async function getStripeCustomerID(
  providedEmail,
  fname,
  lname,
  companyname
) {
  // Retrieve the current customerID from the currently logged in user
  // getUserFromDB() is *your* implemention of getting user info from the DB

  // let options = {
  //   body: {
  //     email: providedEmail,
  //   },
  // }
  let options = {
    email: providedEmail,
  }

  console.log('actions - getStripeCustomerID - options')
  console.log(options)

  // const { email, billingId } = await API.post(apiName, 'customer/get', options)
  const { data } = await axios.post(
    `${process.env.GATSBY_API_URL_MKT}customer/get`,
    options
  )

  console.log('actions - getStripeCustomerID - data')
  console.log(data)

  // const { customerID } = 'getUserFromDB()'
  if (!data?.customer?.billingId) {
    console.log('actions - getStripeCustomerID - start')

    const retrievedCustomer = await createCustomer(
      providedEmail,
      fname,
      lname,
      companyname
    )

    console.log('actions - getStripeCustomerID - retrievedCustomer')
    console.log(retrievedCustomer)

    if (retrievedCustomer && retrievedCustomer.error) {
      return retrievedCustomer.error
    }

    return { mode: 'new', id: retrievedCustomer?.id }
  } else {
    return { mode: 'existing', id: data.customer.billingId }
  }
  // return billingId

  // return 'cus_InRDASulpofb4u'
}

export async function createSubscription(
  customerID,
  paymentMethodID,
  provisioner
) {
  // let options = {
  //   body: {
  //     customerID,
  //     paymentMethodID,
  //     qty,
  //   },
  // }
  // return async (dispatch) => {
  let options = {
    customerID,
    paymentMethodID,
    provisioner,
  }

  try {
    // const request = await API.post(
    //   apiName,
    //   'stripe/create-subscription',
    //   options
    // )
    const request = await axios
      .post(
        `${process.env.GATSBY_API_URL_MKT}stripe/create-subscription`,
        options
      )
      .catch((err) => {
        console.log('**err')
        console.log(err)
        throw err
      })

    const subscription = request

    console.log('actions - createSubscription - subscription')
    console.log(subscription)

    return subscription
  } catch (error) {
    console.log('Failed to create subscription - error')
    console.log(error)
    console.log('error.message')
    console.log(error.message)
    console.log('error.response')
    console.log(error.response)

    return { error: error.response.data.message }
    // return dispatch(updatePurchaseErrors(error.response.data))

    // return
    // }
  }

  // .catch((error) => {
  //   console.log('actions - createSubscription - API.POST - error')
  //   console.log(error)
  // })

  // const request = await fetch(
  //   `${process.env.GATSBY_API_URL_MKT}stripe/create-subscription`,
  //   {
  //     method: 'POST',
  //     body: JSON.stringify({
  //       customerID,
  //       paymentMethodID,
  //     }),
  //   }
  // )
  // const subscription = await request.json()

  // TODO
  // Update your user in DB to store the subscriptionID and enable paid plan
  // updateUserInDB() is *your* implementation of updating a user in the DB
  // updateUserInDB({
  //   paymentMethodID,
  //   hasPaidPlan: true,
  //   subscriptionID: subscription.id,
  // })
}

export function updatePaymentIntentId(id) {
  return (dispatch) => {
    console.log('actions - stripeManager - v')
    dispatch({
      type: 'PAYMENT_INTENT_ID_UPDATE',
      data: id,
    })
  }
}
export function updateFulfillEmail(email) {
  return (dispatch) => {
    console.log('actions - stripeManager - updateFulfillEmail')
    dispatch({
      type: 'FULFILL_ORDER_EMAIL',
      data: email,
    })
  }
}
export async function createPayment(
  customerID,
  paymentMethodID,
  provisioner,
  pageContext,
  mode
) {
  console.log('actions - stripeManager - createPayment')
  // let options = {
  //   body: {
  //     customerID,
  //     paymentMethodID,
  //     qty,
  //   },
  // }
  // return async (dispatch) => {
  let options = {
    paymentMethodID,
    provisioner,
    mode,
  }
  if (customerID) options.customerID = customerID
  if (pageContext) options.pageContext = pageContext

  try {
    // const request = await API.post(
    //   apiName,
    //   'stripe/create-subscription',
    //   options
    // )
    const request = await axios.post(
      `${process.env.GATSBY_API_URL_MKT}stripe/create-payment`,
      options
    )
    return request

    // return request.then((requestData) => {
    //   console.log('actions - createPayment - POST - requestData')
    //   console.log(requestData)

    //   if (requestData && requestData.data && requestData.data.fulfill) {
    //     console.log('dispatch1')
    //     dispatch({
    //       type: 'FULFILL_ORDER_EMAIL',
    //       data: requestData.data.fulfill,
    //     })
    //     return requestData.data.fulfill
    //   } else {
    //     console.log('dispatch2')

    //     return requestData.data
    //   }
    // })
  } catch (error) {
    console.log('Failed to create payment - error')
    console.log(error)
    console.log('error.message')
    console.log(error.message)
    console.log('error.response')
    console.log(error.response)

    return { error: error.response.data.message }
    // return dispatch(updatePurchaseErrors(error.response.data))

    // return
    // }
  }

  // .catch((error) => {
  //   console.log('actions - createSubscription - API.POST - error')
  //   console.log(error)
  // })

  // const request = await fetch(
  //   `${process.env.GATSBY_API_URL_MKT}stripe/create-subscription`,
  //   {
  //     method: 'POST',
  //     body: JSON.stringify({
  //       customerID,
  //       paymentMethodID,
  //     }),
  //   }
  // )
  // const subscription = await request.json()

  // TODO
  // Update your user in DB to store the subscriptionID and enable paid plan
  // updateUserInDB() is *your* implementation of updating a user in the DB
  // updateUserInDB({
  //   paymentMethodID,
  //   hasPaidPlan: true,
  //   subscriptionID: subscription.id,
  // })
}

export async function handleSubscription(subscriptionID, end) {
  const request = await fetch(
    `${process.env.GATSBY_API_URL_MKT}stripe/mkt/handle-subscription`,
    {
      method: 'POST',
      body: JSON.stringify({
        subscriptionID,
        end,
      }),
    }
  )
  return await request.json()
}

export async function retrieveSubscription(subscriptionID) {
  const request = await fetch(
    `${process.env.GATSBY_API_URL_MKT}stripe/retrieve-subscription`,
    {
      method: 'POST',
      body: JSON.stringify({
        subscriptionID,
      }),
    }
  )
  return await request.json()
}

export async function updatePaymentMethod(customerID, paymentMethodID) {
  await fetch(`${process.env.GATSBY_API_URL_MKT}stripe/update-payment-method`, {
    method: 'POST',
    body: JSON.stringify({
      customerID,
      paymentMethodID,
    }),
  })
  // Update your user in DB to store the new payment method
  // updateUserInDB() is *your* implementation of updating a user in the DB
  updateUserInDB({ paymentMethodID })
}

export async function retreivePaymentInfo(paymentMethodID) {
  try {
    const request = await fetch(
      `${process.env.GATSBY_API_URL_MKT}stripe/retrieve-payment-method`,
      {
        method: 'POST',
        body: JSON.stringify({
          paymentMethodID,
        }),
      }
    )
    const result = await request.json()
    return {
      type: result.card.brand,
      digits: result.card.last4,
    }
  } catch (error) {
    console.error(error)
    return null
  }
}

export async function retryInvoice(customerID, paymentMethodID, invoiceID) {
  const request = await fetch(
    `${process.env.GATSBY_API_URL_MKT}stripe/retry-invoice`,
    {
      method: 'POST',
      body: JSON.stringify({
        customerID,
        paymentMethodID,
        invoiceID,
      }),
    }
  )
  await request.json()
}

export async function retrievePromoCode(promoCode, customerId) {
  let options = {
    promoCode,
    customerId,
  }

  try {
    const request = await axios
      .post(`${process.env.GATSBY_API_URL_MKT}stripe/retrieve-promo`, options)
      .catch((err) => {
        console.log('**err')
        console.log(err)
        throw err
      })

    const promo = request && request.data ? request.data : {}

    console.log('actions - retrievePromoCode - promo')
    console.log(promo)

    return promo
  } catch (error) {
    console.log('Failed to create subscription - error')
    console.log(error)
    console.log('error.message')
    console.log(error.message)
    console.log('error.response')
    console.log(error.response)

    return { error: error.response.data.message }
  }
}
export async function retrievePaymentIntentStatus(paymentIntentId) {
  let options = {
    paymentIntentId,
  }

  try {
    const request = await axios
      .post(
        `${process.env.GATSBY_API_URL_MKT}stripe/retrieve-payment-intent`,
        options
      )
      .catch((err) => {
        console.log('**err')
        console.log(err)
        throw err
      })

    const status = request && request.data ? request.data : {}

    console.log('actions - retrievePaymentIntentStatus - status')
    console.log(status)

    return status
  } catch (error) {
    console.log('Failed to retrievePaymentIntentStatus - error')
    console.log(error)
    console.log('error.message')
    console.log(error.message)
    console.log('error.response')
    console.log(error.response)

    return { error: error.response.data.message }
  }
}
