//React
import React from 'react'
import { connect } from 'react-redux'

//Gatsby
import { StaticImage } from 'gatsby-plugin-image'

//Packages
import Helmet from 'react-helmet'

//Components
import Layout from '../../components/layout'
import Metatags from '../../components/marketing/metatags'
import PurchaseComponent from '../../components/purchase/purchaseComponent'

import ContactLeadForm from '../../components/marketing/contactLeadForm'

const PreviewPages = [
  'images/guide/passadicos/preview/travelguide-passadicos4.png',
  'images/guide/passadicos/preview/travelguide-passadicos7.png',
  'images/guide/passadicos/preview/travelguide-passadicos10.png',
  'images/guide/passadicos/preview/travelguide-passadicos23.png',
  'images/guide/passadicos/preview/travelguide-passadicos24.png',
]
//Redux
const mapStateToProps = ({
  fulfillOrderEmail,
  paymentIntentId,
  purchasePositionNumber,
}) => {
  return {
    fulfillOrderEmail,
    paymentIntentId,
    purchasePositionNumber,
  }
}
const Snippet = (props) => {
  const { fulfillOrderEmail, paymentIntentId, purchasePositionNumber } = props
  return (
    <Layout>
      <div class="flex-grow-1">
        <Metatags
          title="Sidepath - Passadiços do Paiva and 516 Arouca Bridge, Portugal"
          description="Start your travels with a guide on the best things to do in Passadiços do Paiva"
          image={`https://${process.env.HOSTNAME}/static/bf3ec349aaf7d4c7a9df467957cf993b/1d2c9/01-cover.png`}
        />
        <div class="container">
          {fulfillOrderEmail ||
          paymentIntentId ||
          purchasePositionNumber === 3 ? (
            <div class="row my-3">
              <div className="col-lg-12">
                <PurchaseComponent />
              </div>
            </div>
          ) : null}
          <div class="row my-3">
            <div
              class={`col-lg-8 ${
                fulfillOrderEmail || purchasePositionNumber === 3
                  ? 'offset-lg-2'
                  : ''
              } p-4`}
            >
              <div class="blue-1 landing-subhead">Travel Guide eBook</div>
              <h1 class="h2">
                <span class="font-weight-normal">
                  Passadiços do Paiva, Portugal
                </span>

                <br />
                <span class="font-weight-light">and the 516 Arouca Bridge</span>
              </h1>
              <p class="lead line-height-4 py-3">
                Start planning your trip with this eBook travel guide about
                Passadiços do Paiva and 516 Arouca suspension bridge near
                Arouca, Portugal. Gain access to a wealth of curated
                information, maps, trail descriptions, and activity suggestions
                in this 60 page guide &mdash; all designed to maximize your time
                while you explore.
              </p>
              <div class="d-flex flex-row align-items-center justify-content-center my-4">
                <div>
                  <div class="guide-cover-image border-1-gray-5 d-flex align-items-center justify-content-center">
                    <StaticImage
                      src="../../images/guide/passadicos/01-cover.png"
                      alt="Passadiços do Paiva Travel Guide"
                      loading="lazy"
                      placeholder="blurred"
                      // layout="fullWidth"
                      width={300}
                      // className="mx-3"
                      // height={400}
                      // style={{ position: `absolute` }}
                    />
                  </div>
                </div>
                <StaticImage
                  src="../../images/guide/passadicos/02-composite.png"
                  alt="Passadiços do Paiva Travel Guide"
                  loading="lazy"
                  placeholder="blurred"
                  // layout="fullWidth"
                  width={260}
                  className="mx-3"
                  // height={400}
                  // style={{ position: `absolute` }}
                />
              </div>
              <div class=" bg-gray-7 rounded-corners p-3">
                <strong>
                  In this Passadiços do Paiva travel guide, you'll find:
                </strong>
                <ul>
                  <li>
                    60 pages of curated travel suggestions designed to maximize
                    your precious time on your trip{' '}
                  </li>
                  {/* <li>
                  Expertly crafted half-day, 1-day, 2-day and 3-day itineraries
                  that prioritize the right things to do in the time available
                </li> */}
                  <li>
                    In-depth descriptions and maps for the Passadiços do Paiva
                    trail and 516 Arouca Bridge
                  </li>
                  <li>
                    Overview of the different routes and journey options to
                    exploring this dramatic, scenic region
                  </li>
                  <li>
                    Thoughtful suggestions on area lodging, restaurants, and
                    experiences to help you immerse yourself in your journey{' '}
                  </li>
                  <li>
                    Practical information on ticketing, transport, and essential
                    services to help you take care of logistics and have a
                    successful journey
                  </li>
                  <li>
                    Useful tips and information to get the most out of the
                    experience if traveling with children
                  </li>
                  <li>
                    Ideas for exploring beyond Passadiços do Paiva, including
                    other top activities in the region to consider
                  </li>
                  {/* <li>
                  Area-specific ways to prioritize wellness and giving back to
                  the community as you travel{' '}
                </li> */}
                  {/* <li>
                  Information to help you understand Passadiços do Paiva more
                  fully &mdash; including about human history, geology,
                  wildlife, and more
                </li> */}
                </ul>
              </div>
              {/* <div class="d-flex flex-row align-items-center justify-content-center my-4">
          
          </div> */}

              <hr size={1} />

              <button
                class="btn btn-slim h6 w-100 text-left font-weight-bold"
                type="button"
                data-toggle="collapse"
                data-target="#tocContainer"
                aria-expanded="false"
              >
                <span class="caret d-inline-block"></span> Table of Contents (60
                pages)
              </button>
              <div id="tocContainer" class="collapse">
                <div class="plan-selected d-flex flex-column align-items-start justify-content-start">
                  <div class="plan-selected-option mt-1">
                    <div class="font-weight-light">
                      Overview
                      <br />
                      Top Attractions
                      <br />
                      Locating Passadiços do Paiva
                      <br />
                      Getting There
                      <br />
                      Routes Overview
                      <br />
                      <div class="pl-3">
                        Route - Passadiços do Paiva: Areinho to Espiunca
                        <br />
                        Route - Passadiços do Paiva: Espiunca to Areinho
                        <br />
                        Route - Passadiços do Paiva: Round trip
                        <br />
                        Route - 516 Arouca Bridge: From Areinho
                        <br />
                        Route - 516 Arouca Bridge: From Alverenga
                        <br />
                      </div>
                      Hiking: Passadiços do Paiva
                      <br />
                      <div class="pl-3">
                        Areinho trailhead
                        <br />
                        Stairs Ascent
                        <br />
                        Areinho Meeting Point
                        <br />
                        516 Arouca Bridge
                        <br />
                        Aguierias Waterfall Viewpoint
                        <br />
                        On the Trail to Vau
                        <br />
                        Vau
                        <br />
                        From Vau to Gola do Salto
                        <br />
                        To Espiunca
                      </div>
                      Hiking: 516 Arouca Bridge
                      <br />
                      <div class="pl-3">
                        Areinho trailhead
                        <br />
                        Alverenga trailhead
                        <br />
                        Guided tour
                        <br />
                        Crossing the bridge
                      </div>
                      Visiting Passadiços do Paiva
                      <br />
                      <div class="pl-3">
                        When to go
                        <br />
                        Tickets
                        <br />
                        Kids
                        <br />
                        Restaurants
                        <br />
                        Transport
                        <br />
                        Lodging
                        <br />
                        Essentials
                        <br />
                      </div>
                      Exploring beyond
                      <br />
                    </div>
                  </div>
                </div>
              </div>
              <hr size={1} />

              <button
                class="btn btn-slim h6 w-100 text-left font-weight-bold"
                type="button"
                data-toggle="collapse"
                data-target="#whyContainer"
                aria-expanded="false"
              >
                <span class="caret d-inline-block"></span> Why the Passadiços do
                Paiva Travel Guide from Sidepath?
              </button>

              <div id="whyContainer" class="collapse">
                <div class="plan-selected d-flex flex-column align-items-start justify-content-start">
                  <div class="plan-selected-option mt-1">
                    <ul>
                      <li>
                        Get access to curated suggestions, thorough research,
                        and expert experience about Passadiços do Paiva and 516
                        Arouca Bridge to maximize your trip
                      </li>
                      <li>
                        Let us share our experience and research with you, and
                        avoid sifting through travel blogs, restaurant review
                        sites, Airbnb listings, and more
                      </li>
                      <li>
                        Make your journey easier with useful information on
                        ticketing, getting there, transport, and other essential
                        information
                      </li>
                      {/* <li>
                      Travel sustainably in the community with our thoughtful
                      info on wellness activities, ways to give back, and how to
                      live like a local
                    </li> */}
                      {/* <li>
                      Experience the area more fully with useful context on the
                      forces that have shaped the region: human history,
                      geology, wildlife and more{' '}
                    </li> */}
                      <li>
                        Use a consolidated, all-in-one guide while you're
                        on-the-go with beautifully designed maps and imagery to
                        help you immerse yourself with a sense of place
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <hr size={1} />
              <div class="p-0 h6 w-100 text-left font-weight-bold">
                Passadiços do Paiva Travel guide sample
              </div>
              <div class="d-flex flex-row align-items-center justify-content-center my-4 w-100">
                {/* BEGIN CAROUSEL */}
                <div
                  id="previewCarousel"
                  class="carousel carousel-preview"
                  data-ride="false"
                  align="center"
                >
                  {/* Slides */}
                  <div class="carousel-inner">
                    {/* {PreviewPages.map((previewItem, i) => (
               
                    <div class={`carousel-item ${i === 0 ? 'active' : ''}`}>
                      <StaticImage
                        src={`../../${previewItem}`}
                        alt="Passadiços do Paiva Travel Guide"
                        loading="lazy"
                        placeholder="blurred"
                        // layout="fullWidth"
                        width={300}
                        className="mx-3"
                        height={400}
                        // style={{ position: `absolute` }}
                      />
                    </div>
                  ))} */}
                    <div class={`carousel-item active`}>
                      <StaticImage
                        src={`../../images/guide/passadicos/preview/travelguide-passadicos12.png`}
                        alt="Passadiços do Paiva Travel Guide"
                        loading="lazy"
                        placeholder="blurred"
                        width={500}
                      />
                    </div>
                    <div class={`carousel-item`}>
                      <StaticImage
                        src={`../../images/guide/passadicos/preview/travelguide-passadicos10.png`}
                        alt="Passadiços do Paiva Travel Guide"
                        loading="lazy"
                        placeholder="blurred"
                        width={500}
                      />
                    </div>
                    <div class={`carousel-item`}>
                      <StaticImage
                        src={`../../images/guide/passadicos/preview/travelguide-passadicos4.png`}
                        alt="Passadiços do Paiva Travel Guide"
                        loading="lazy"
                        placeholder="blurred"
                        width={500}
                      />
                    </div>
                    <div class={`carousel-item`}>
                      <StaticImage
                        src={`../../images/guide/passadicos/preview/travelguide-passadicos23.png`}
                        alt="Passadiços do Paiva Travel Guide"
                        loading="lazy"
                        placeholder="blurred"
                        width={500}
                      />
                    </div>
                    <div class={`carousel-item`}>
                      <StaticImage
                        src={`../../images/guide/passadicos/preview/travelguide-passadicos7.png`}
                        alt="Passadiços do Paiva Travel Guide"
                        loading="lazy"
                        placeholder="blurred"
                        width={500}
                      />
                    </div>
                    <div class={`carousel-item`}>
                      <StaticImage
                        src={`../../images/guide/passadicos/preview/travelguide-passadicos24.png`}
                        alt="Passadiços do Paiva Travel Guide"
                        loading="lazy"
                        placeholder="blurred"
                        width={500}
                      />
                    </div>
                  </div>

                  {/* Left right */}
                  {/* <a
                  class="carousel-control-prev"
                  href="#previewCarousel"
                  data-slide="prev"
                >
                  <span class="carousel-control-prev-icon"></span>
                </a>
                <a
                  class="carousel-control-next"
                  href="#previewCarousel"
                  data-slide="next"
                >
                  <span class="carousel-control-next-icon"></span>
                </a> */}

                  {/* Thumbnails */}
                  <ol class="carousel-indicators list-inline">
                    {/* {PreviewPages.map((previewItem, i) => (
                    <li class={`list-inline-item ${i === 0 ? 'active' : ''}`}>
                      <a
                        id={`carousel-selector-${i}`}
                        class="selected"
                        data-slide-to={i}
                        data-target="#previewCarousel"
                      >
                        <img src={`../../${previewItem}`} class="img-fluid" />
                      </a>
                    </li>
                  ))} */}
                    <li class={`list-inline-item active`}>
                      <a
                        id={`carousel-selector-0`}
                        class="selected"
                        data-slide-to={0}
                        data-target="#previewCarousel"
                      >
                        <StaticImage
                          src={`../../images/guide/passadicos/preview/travelguide-passadicos12.png`}
                          alt="Passadiços do Paiva Travel Guide"
                          loading="lazy"
                          placeholder="blurred"
                          width={50}
                        />
                      </a>
                    </li>
                    <li class={`list-inline-item`}>
                      <a
                        id={`carousel-selector-1`}
                        class="selected"
                        data-slide-to={3}
                        data-target="#previewCarousel"
                      >
                        <StaticImage
                          src={`../../images/guide/passadicos/preview/travelguide-passadicos10.png`}
                          alt="Passadiços do Paiva Travel Guide"
                          loading="lazy"
                          placeholder="blurred"
                          width={50}
                        />
                      </a>
                    </li>
                    <li class={`list-inline-item`}>
                      <a
                        id={`carousel-selector-2`}
                        class="selected"
                        data-slide-to={1}
                        data-target="#previewCarousel"
                      >
                        <StaticImage
                          src={`../../images/guide/passadicos/preview/travelguide-passadicos4.png`}
                          alt="Passadiços do Paiva Travel Guide"
                          loading="lazy"
                          placeholder="blurred"
                          width={50}
                        />
                      </a>
                    </li>
                    <li class={`list-inline-item`}>
                      <a
                        id={`carousel-selector-3`}
                        class="selected"
                        data-slide-to={4}
                        data-target="#previewCarousel"
                      >
                        <StaticImage
                          src={`../../images/guide/passadicos/preview/travelguide-passadicos23.png`}
                          alt="Passadiços do Paiva Travel Guide"
                          loading="lazy"
                          placeholder="blurred"
                          width={50}
                        />
                      </a>
                    </li>
                    <li class={`list-inline-item`}>
                      <a
                        id={`carousel-selector-4`}
                        class="selected"
                        data-slide-to={2}
                        data-target="#previewCarousel"
                      >
                        <StaticImage
                          src={`../../images/guide/passadicos/preview/travelguide-passadicos7.png`}
                          alt="Passadiços do Paiva Travel Guide"
                          loading="lazy"
                          placeholder="blurred"
                          width={50}
                        />
                      </a>
                    </li>

                    <li class={`list-inline-item`}>
                      <a
                        id={`carousel-selector-5`}
                        class="selected"
                        data-slide-to={5}
                        data-target="#previewCarousel"
                      >
                        <StaticImage
                          src={`../../images/guide/passadicos/preview/travelguide-passadicos24.png`}
                          alt="Passadiços do Paiva Travel Guide"
                          loading="lazy"
                          placeholder="blurred"
                          width={50}
                        />
                      </a>
                    </li>
                  </ol>
                </div>
                {/* END CAROUSEL */}
              </div>
              <hr size={1} />

              <p class="lead line-height-4 mt-4">
                Receive your copy of the Passadiços do Paiva Travel Guide by
                filling out the form
              </p>
            </div>

            {!fulfillOrderEmail &&
            !paymentIntentId &&
            purchasePositionNumber !== 3 ? (
              <div class="col-lg-4 landing-offer-col">
                <PurchaseComponent />
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </Layout>
  )
}

// export default Snippet
export default connect(mapStateToProps, null)(Snippet)
