import React, { useState } from 'react'
import { Link } from 'gatsby'

import { FaArrowRight } from 'react-icons/fa'

const Snippet = (props) => {
  const {
    emailInput,
    updateEmailInput,
    firstNameInput,
    setFirstNameInput,
    lastNameInput,
    setLastNameInput,
    companyNameInput,
    setCompanyNameInput,
    updatePurchasePositionNumber,
    errorMessages,
    setErrorMessages,
    purchaseErrors,
    updatePurchaseErrors,
    provisionedUsersNumber,
    setProvisionedUsersNumber,
    purchasePlan,
    updatePurchasePlan,
    priceVal,
  } = props

  console.log('purchaseUserDetails -- provisionedUsersNumber')
  console.log(provisionedUsersNumber)
  return (
    <>
      <div class="row d-flex align-items-center justify-content-center">
        <div
          // class="col-md-7 col-lg-5"
          class="col-lg-12"
        >
          {/* <hr size={1} /> */}

          {/* <div class="h6">Account details</div> */}
          {/* <div class="product-selected">
            <div class="plan-selected d-flex flex-row align-items-center justify-content-start">
              <label class="w-25">Plan</label>
              <div class="plan-selected-option">
                <select
                  class="form-control form-control-sm"
                  value={purchasePlan}
                  onChange={(e) => {
                    updatePurchasePlan(e.target.value)
                  }}
                  tabIndex="1"
                >
                  <option value="standard">Standard</option>
                  <option value="pro">Pro</option>
                  <option value="enterprise">Enterprise</option>
                </select>
              </div>
            </div>
            <div class="plan-users-selected d-flex flex-row align-items-center justify-content-start">
              <label class="w-25">Users</label>
              <div class="plan-users-selected-option">
                <select
                  class="form-control form-control-sm"
                  value={provisionedUsersNumber}
                  onChange={(e) => {
                    setProvisionedUsersNumber(e.target.value)
                  }}
                  tabIndex="2"
                >
                  <option>1</option>
                  <option>2</option>
                  <option>3</option>
                  <option>4</option>
                  <option>5</option>
                  <option>6</option>
                  <option>7</option>
                  <option>8</option>
                  <option>9</option>
                  <option>10</option>
                  <option value={11}>11+</option>
                </select>
              </div>
            </div>
            {purchasePlan === 'enterprise' ||
            provisionedUsersNumber > 10 ? null : (
              <div class="plan-calc-price-container d-flex flex-row align-items-start justify-content-start pt-1">
                <label class="w-25">Total</label>
                <div class="plan-calc-price small gray-1">
                  <div class="font-weight-light"> Free 7-day trial</div>
                  <div class="font-weight-light line-height-0">
                    Afterwards ${priceVal}/user/mo. x {provisionedUsersNumber}{' '}
                    user{provisionedUsersNumber > 1 ? 's' : ''} ={' '}
                    <span class="font-weight-bold">
                      ${(priceVal * provisionedUsersNumber).toFixed(2)}/mo.
                    </span>
                  </div>
                </div>
              </div>
            )}
          </div> */}

          {/* <hr size={1} /> */}

          {/* {purchasePlan === 'enterprise' || provisionedUsersNumber > 10 ? (
            <>
              <div class="h6">Contact us</div>
              <p class="font-weight-light line-height-2">
                We'd love to help you with a plan that meets your needs. Please
                contact us using the link below.
              </p>
              <Link
                class={`btn btn-primary btn-block mt-2`}
                to={
                  purchasePlan === 'enterprise'
                    ? '/request-demo/?c=purchase-ent'
                    : `/request-demo/?c=purchase-${purchasePlan}-11`
                }
              >
                Contact us <FaArrowRight style={{ marginTop: -3 }} />
              </Link>
            </>
          ) : ( */}
          <>
            <div class="h6">Your info</div>
            <div class="form-group">
              <label for="userFirstName" class="font-weight-light small">
                First name
              </label>
              <input
                type="text"
                class={`form-control ${
                  purchaseErrors && purchaseErrors.fname ? 'is-invalid' : ''
                }`}
                id="userFirstName"
                placeholder="First name"
                value={firstNameInput}
                onChange={(e) => setFirstNameInput(e.currentTarget.value)}
                tabIndex="1"
              />
            </div>
            <div class="form-group">
              <label for="userLastName" class="font-weight-light small">
                Last name
              </label>
              <input
                type="text"
                class={`form-control ${
                  purchaseErrors && purchaseErrors.lname ? 'is-invalid' : ''
                }`}
                id="userLastName"
                placeholder="Last name"
                value={lastNameInput}
                onChange={(e) => setLastNameInput(e.currentTarget.value)}
                tabIndex="2"
              />
            </div>
            {/* <div class="form-group">
                <label for="companyName">Company name</label>
                <input
                  type="text"
                  class={`form-control form-control-sm ${
                    purchaseErrors && purchaseErrors.companyName
                      ? 'is-invalid'
                      : ''
                  }`}
                  id="companyName"
                  aria-describedby="companyNameHelp"
                  placeholder="Company name"
                  value={companyNameInput}
                  onChange={(e) => setCompanyNameInput(e.currentTarget.value)}
                  tabIndex="5"
                />
              </div> */}
            <div class="form-group mb-0">
              <label for="userEmail" class="font-weight-light small">
                Email address
              </label>
              <input
                type="email"
                class={`form-control ${
                  purchaseErrors &&
                  (purchaseErrors.email || purchaseErrors.emailValid)
                    ? 'is-invalid'
                    : ''
                }`}
                id="userEmail"
                aria-describedby="userEmailHelp"
                placeholder="Email"
                value={emailInput}
                onChange={(e) => updateEmailInput(e.currentTarget.value)}
                tabIndex="3"
              />
              <small id="userEmailHelp" class="form-text text-muted">
                We'll send your guide to this address
              </small>
            </div>
          </>
          {/* )} */}
        </div>
      </div>
    </>
  )
}

export default Snippet
