export function updatePurchaseLoading(bool) {
  console.log('actions - updatePurchaseLoading')
  console.log(bool)
  return (dispatch) => {
    dispatch({
      type: 'PURCHASE_LOADING_UPDATE',
      data: bool,
    })
  }
}
export function updatePurchasePositionNumber(pos) {
  return (dispatch) => {
    dispatch({
      type: 'PURCHASE_POSITION_UPDATE',
      data: pos,
    })
  }
}
export function updatePurchaseErrors(error) {
  return (dispatch) => {
    dispatch({
      type: 'PURCHASE_ERRORS_UPDATE',
      data: error,
    })
  }
}
export function updatePurchasePlan(plan) {
  return (dispatch) => {
    dispatch({
      type: 'PURCHASE_PLAN_UPDATE',
      data: plan,
    })
  }
}
export function setProvisionedUsersNumber(num) {
  return (dispatch) => {
    dispatch({
      type: 'PURCHASE_USERS_UPDATE',
      data: num,
    })
  }
}
