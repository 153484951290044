import React, { useState } from 'react'

import { Link } from 'gatsby'

//Components
import SuccessAnimation from '../animation/successAnimation'

//Packages
import { CopyToClipboard } from 'react-copy-to-clipboard'

//Icons
import { IoMailUnreadOutline, IoPeopleOutline } from 'react-icons/io5'
// import { BsBookmarkHeart } from 'react-icons/bs'
import { FiLogIn } from 'react-icons/fi'
import { RiMessage2Line, RiHandHeartLine } from 'react-icons/ri'
import { HiOutlineClipboardCheck } from 'react-icons/hi'

const Snippet = (props) => {
  const {
    emailInput,
    provisionedUsersNumber,
    outboundReferralCode,
    copiedToClipboard,
    setCopiedToClipboard,
    fulfillOrderEmail,
  } = props

  console.log('purchaseSuccess - emailInput')
  console.log(emailInput)
  console.log('purchaseSuccess - outboundReferralCode')
  console.log(outboundReferralCode)
  return (
    <>
      {copiedToClipboard ? (
        <div class="copied-clipboard fade-in rounded-corners gradient-callout-algae white-1 small px-4 py-3">
          Copied code to clipboard
        </div>
      ) : null}
      <div class="row mt-1 d-flex align-items-center justify-content-center">
        <div class="col-md-12">
          {/* <hr size={1} /> */}
          <SuccessAnimation />
          <div class="h3 my-2 text-center">Success!</div>
          <p class="lead text-center line-height-3 pb-2">
            We've sent your guide to{' '}
            <span class="font-weight-normal">
              {emailInput
                ? emailInput
                : fulfillOrderEmail
                ? fulfillOrderEmail
                : ''}
            </span>
          </p>
          <div class="purchase-success-next-steps mt-4 font-weight-light line-height-2">
            <div class="purchase-success-next-step-item d-flex flex-row align-items-center justify-content-center">
              <div class="purchase-success-next-step-icon">
                <IoMailUnreadOutline size={32} class="dkblue-1" />
              </div>
              <div class="">
                Make sure to check your spam folder if you don't see it right
                away.
              </div>
            </div>

            <div class="purchase-success-next-step-item d-flex flex-row align-items-center justify-content-center">
              <div class="purchase-success-next-step-icon">
                <RiHandHeartLine
                  size={32}
                  class="pink-1"
                  style={{ marginTop: 3 }}
                />
              </div>
              <div>
                If you need help with your order, please contact us at{' '}
                <span class="font-weight-normal">support@sidepath.xyz</span>
              </div>
            </div>
            {/* {provisionedUsersNumber > 1 ? (
              <div class="purchase-success-next-step-item d-flex flex-row align-items-start justify-content-center">
                <div class="purchase-success-next-step-icon">
                  <IoPeopleOutline
                    size={32}
                    class="purple-1"
                    style={{ marginTop: 3 }}
                  />
                </div>
                <div>
                  You can add other users to your account in the Account
                  Settings section of the{' '}
                  <a href={process.env.CONSOLE_DOMAIN} target="_blank">
                    Sidepath console
                  </a>
                </div>
              </div>
            ) : outboundReferralCode ? (
              <div class="purchase-success-next-step-item d-flex flex-row align-items-start justify-content-center">
                <div class="purchase-success-next-step-icon">
                  <RiMessage2Line
                    size={32}
                    class="dkgreen-1"
                    style={{ marginTop: 3 }}
                  />
                </div>
                <div>
                  Share your referral code below to earn special benefits and
                  perks with Sidepath!{' '}
                  <Link to="/about/referral/">Learn more</Link>
                  <br />
                  <br />
                  <span class="font-weight-normal">
                    {outboundReferralCode.replace(
                      /(.{3})(.{3})(.{3})/,
                      '$1-$2-$3'
                    )}
                  </span>
                  <CopyToClipboard
                    text={`Create short-form videos to engage your audience at Sidepath - Use code: ${outboundReferralCode.replace(
                      /(.{3})(.{3})(.{3})/,
                      '$1-$2-$3'
                    )}`}
                    onCopy={() => {
                      setCopiedToClipboard(true)
                      setTimeout(() => {
                        setCopiedToClipboard(false)
                      }, 8000)
                    }}
                  >
                    <button class="ml-3 btn btn-sm btn-outline-success">
                      Copy code{' '}
                      <HiOutlineClipboardCheck
                        size={16}
                        style={{ marginTop: -4 }}
                        class="copy-icon"
                      />
                    </button>
                  </CopyToClipboard>
                </div>
              </div>
            ) : (
              <div class="purchase-success-next-step-item d-flex flex-row align-items-start justify-content-center">
                <div class="purchase-success-next-step-icon">
                  <FiLogIn
                    size={32}
                    class="purple-1"
                    style={{ marginTop: 3 }}
                  />
                </div>
                <div>
                  Get started by signing in to the{' '}
                  <a href={process.env.CONSOLE_DOMAIN} target="_blank">
                    Sidepath console
                  </a>{' '}
                  with the details in your welcome email
                </div>
              </div>
            )} */}
          </div>
          {/* <hr size={1} /> */}
        </div>
      </div>
    </>
  )
}

export default Snippet
