import React, { useState, useEffect } from 'react'

import { FaSpinner, FaArrowRight } from 'react-icons/fa'

const Snippet = (props) => {
  const {
    isLoadingPurchase,
    buttonAction,
    purchasePositionNumber,
    firstNameInput,
    lastNameInput,
    companyNameInput,
    emailInput,
    updatePurchaseErrors,
    updatePurchasePositionNumber,
    validateEmail,
    cardErrorMessage,
    cardNameErrorMessage,
    cardExpirationErrorMessage,
    cardCVCErrorMessage,
    setCardErrorMessage,
    setCardNameErrorMessage,
    setCardExpirationErrorMessage,
    setCardCVCErrorMessage,
    nameInput,
    setNameInput,
    purchasePlan,
    provisionedUsersNumber,
    provideCCNowInput,
    referralCodeRawInput,
    PaymentRequestButtonElement,
    paymentRequest,
    handlePaymentButtonClicked,
  } = props

  console.log('purchaseButtons - isLoadingPurchase')
  console.log(isLoadingPurchase)
  return (
    <div class="row mt-1 pt-1 d-flex align-items-center justify-content-center">
      <div class="col-lg-12 d-flex flex-column align-items-center justify-content-center">
        {(purchasePositionNumber === 1 || purchasePositionNumber === 2) &&
        purchasePlan !== 'enterprise' &&
        provisionedUsersNumber <= 10 ? (
          <>
            {purchasePositionNumber === 2 ? (
              <>
                {/* <hr size={1} /> */}
                <div class="mb-3 small gray-1 text-center line-height-0">
                  By pressing Submit, you are creating a Sidepath account, and
                  you agree to the Sidepath{' '}
                  <a target="_blank" href="https://sidepath.xyz/about/terms/">
                    Terms
                  </a>{' '}
                  and{' '}
                  <a target="_blank" href="https://sidepath.xyz/about/privacy/">
                    Privacy Policy
                  </a>
                </div>
              </>
            ) : null}

            <button
              class={`btn btn-lg btn-primary btn-block`}
              onClick={() => {
                // if (purchasePositionNumber === 1) {
                let collectedErrors = {}
                // if (!firstNameInput || firstNameInput === '') {
                //   // collectedErrors.push('Please enter your first name')
                //   collectedErrors.fname = true
                // }
                // if (!lastNameInput) {
                //   // collectedErrors.push('Please enter your last name')
                //   collectedErrors.lname = true
                // }
                // if (!companyNameInput) {
                //   // collectedErrors.push('Please enter your company name')
                //   collectedErrors.companyName = true
                // }
                if (!emailInput) {
                  // collectedErrors.push('Please enter your email')
                  collectedErrors.email = true
                }
                if (emailInput && validateEmail(emailInput) === false) {
                  // collectedErrors.push("Your email doesn't appear valid")
                  collectedErrors.emailValid = true
                }

                // if (
                //   firstNameInput &&
                //   lastNameInput &&
                //   companyNameInput &&
                //   emailInput &&
                //   validateEmail(emailInput) === true
                // ) {
                //   updatePurchaseErrors({})
                //   updatePurchasePositionNumber(2)
                // } else {
                //   updatePurchaseErrors(collectedErrors)
                // }
                // } else if (purchasePositionNumber === 2) {
                if (provideCCNowInput === true) {
                  if (!nameInput) {
                    setCardNameErrorMessage(
                      'Please enter the name on your card'
                    )
                  } else if (
                    referralCodeRawInput &&
                    referralCodeRawInput.length !== 9
                  ) {
                    updatePurchaseErrors({
                      message: 'Your referral code is incomplete',
                    })
                  } else if (
                    nameInput &&
                    !cardErrorMessage &&
                    !cardExpirationErrorMessage &&
                    !cardCVCErrorMessage
                  ) {
                    setCardNameErrorMessage('')

                    buttonAction()
                  } else {
                    setCardNameErrorMessage('')
                    updatePurchaseErrors({
                      message:
                        'Please correct the errors in your payment info.',
                    })
                  }
                } else {
                  if (
                    referralCodeRawInput &&
                    referralCodeRawInput.length !== 9
                  ) {
                    updatePurchaseErrors({
                      message: 'Your referral code is incomplete',
                    })
                  } else {
                    //provideCCNowInput is false
                    buttonAction()
                  }
                } //end provideCCNowInput
                // } //end purchasePositionNumber
              }}
            >
              {/* {purchasePositionNumber === 2 ? ( */}
              <span>
                Purchase{' '}
                {isLoadingPurchase ? (
                  <FaSpinner
                    size={15}
                    className="icon-spin"
                    style={{ marginLeft: '10px' }}
                  />
                ) : null}
              </span>
              {/* ) : ( */}
              {/* <span>
                  Next{' '}
                  <FaArrowRight class="btn-arrow" style={{ marginTop: -3 }} />
                </span> */}
              {/* )} */}
            </button>
            {paymentRequest ? (
              <div class="payment-request-container mt-2 w-100">
                <PaymentRequestButtonElement
                  options={{ paymentRequest }}
                  onClick={handlePaymentButtonClicked}
                />
              </div>
            ) : null}

            <div class="my-2 gray-1 small w-100 text-center">
              <hr size={1} />
              Need help? Email support@sidepath.xyz
            </div>
          </>
        ) : null}
        {purchasePositionNumber === 2 ? (
          <button
            class="btn btn-link gray-3 btn-block mt-2"
            onClick={() => {
              updatePurchasePositionNumber(1)
              updatePurchaseErrors({})
            }}
          >
            Back
          </button>
        ) : null}
        {purchasePositionNumber === 3 ? (
          <a
            class={`btn btn-primary btn-block`}
            href={process.env.CONSOLE_DOMAIN}
            target="_blank"
          >
            Go to Console{' '}
            <FaArrowRight class="btn-arrow" style={{ marginTop: -3 }} />
          </a>
        ) : null}
      </div>
    </div>
  )
}

export default Snippet
