import React from 'react'
import lottie from 'lottie-web'
import SuccessAnimation from './success.json'

export default class Snippet extends React.Component {
  constructor(props) {
    super(props)
  }

  componentDidMount() {
    lottie.loadAnimation({
      container: document.getElementById('animation-success'), // the dom element that will contain the animation
      renderer: 'svg',
      loop: false,
      autoplay: true,
      animationData: SuccessAnimation,
    })
    // loadAnimation.setSpeed(0.7)
  }
  render() {
    const { message } = this.props
    return (
      <>
        <div class="d-flex flex-row align-items-center justify-content-center">
          <div style={{ width: 60, height: 60 }}>
            <div id="animation-success" />
          </div>
          {message ? (
            <div class="font-weight-light line-height-1 w-50">{message}</div>
          ) : null}
        </div>
      </>
    )
  }
}
